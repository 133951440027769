body {
  font-family: "Microsoft YaHei";
}
.quick-button {
  border: 1px solid #343a40;
  margin-bottom: -1px;
  padding: 30px 0 10px 0;
  font-size: 14px;
  background: #efefef;

  background: -webkit-linear-gradient(top, #fafafa, #efefef);
  background: -moz-linear-gradient(top, #fafafa, #efefef);
  background: -o-linear-gradient(top, #fafafa, #efefef);
  background: -ms-linear-gradient(top, #fafafa, #efefef);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  display: block;
  color: #343a40 !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.quick-button:hover {
  text-decoration: none;
  border-color: #a5a5a5;
  color: gray !important;
  background-color: #efefef;
  text-shadow: 0 1px 0 #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.quick-button i {
  font-size: 32px;
}
.notification {
  position: absolute;
  top: -12px;
  right: -12px;
  line-height: 16px;
  height: 30px;
  padding: 6px 10px;
  color: white !important;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 1px rgba(255, 255, 255, 0.3);
}
.notification.red {
  border-color: #fa5833;
  background-color: #fa603d;
  background: #fa603d;

  background: -webkit-linear-gradient(top, #fc9d88, #fa603d);
  background: -moz-linear-gradient(top, #fc9d88, #fa603d);
  background: -o-linear-gradient(top, #fc9d88, #fa603d);
  background: -ms-linear-gradient(top, #fc9d88, #fa603d);
}
.box {
  margin: 20px 0;
}
.box .box-header {
  height: 42px;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px;
  background-color: #fafafa;
  background: #efefef;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(#efefef)
  );
  background: -webkit-linear-gradient(top, #fafafa, #efefef);
  background: -moz-linear-gradient(top, #fafafa, #efefef);
  background: -o-linear-gradient(top, #fafafa, #efefef);
  background: -ms-linear-gradient(top, #fafafa, #efefef);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
  overflow: hidden;
}
.box .box-header h2 {
  font-size: 16px;
  line-height: 16px;
  float: left;
  margin-top: 2px;
  color: #646464;
  font-weight: normal;
  text-shadow: 0 1px 0 #fff;
}
.box .box-header h2 i {
  margin: 1px 3px 0 5px;
  opacity: 0.4;
}
.box .box-header .break {
  border-left: 1px solid #fcfcfc;
  border-right: 1px solid #ddd;
  margin: -12px 10px -10px 10px;
  padding: 12px 0 10px 0;
}
.box .box-content {
  margin-top: -1px;
  padding: 10px;
  border: 1px solid #ddd;
  background: #fcfcfc;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
}
.box .box-content .header {
  width: 100%;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
}
.box .box-content .header .title {
  float: left;
  text-align: left;
}
.box .box-content .header .number {
  float: right;
  text-align: right;
}
.box .box-content .content {
  line-height: 2rem;
  list-style: none;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ebebeb;
  font-size: 1rem;
}
ul.chat {
  margin: 0;
  padding: 0;
}
ul.chat li {
  list-style: none;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 12px;
}
ul.chat li .message {
  display: block;
  border: 1px solid #ddd;
  padding: 5px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
    inset 0 -1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
    inset 0 -1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
    inset 0 -1px 1px rgba(0, 0, 0, 0.05);
}
ul.chat li.left .message {
  text-align: left;
  margin-left: 65px;
  background: #fff;
  color: blue;
}
ul.chat li.left .message .arrow {
  height: 1rem;
  width: 1rem;
  display: block;
  position: absolute;
  top: 5px;
  left: -10px;
  background: url(/p/image/chat-left.png) no-repeat 0 0;
}
ul.chat li.left .message .text {
  display: block;
}
ul.chat li.right .message {
  text-align: left;
  margin-right: 65px;
  background: #f6f6f6;
  color: brown;
}

ul.chat li.right .message .arrow {
  height: 1rem;
  width: 10px;
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  background: url(/p/image/chat-right.png) no-repeat 0 0;
}
ul.chat li .avatar {
  font-size: 32px;
  height: 50px;
  width: 50px;
  padding: 1px;
  border: 1px solid #ebebeb;
}
ul.chat li.left .avatar {
  float: left;
  margin-right: 10px;
}
ul.chat li.right .avatar {
  float: right;
  margin-left: 10px;
}
.well {
  padding: 19px;
  border: 1px solid #ddd;
  background-color: #f6f6f6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

@media only screen and (max-width: 479px) {
  .quick-button,
  .quick-button-small {
    margin-bottom: 20px;
  }
}

/* .footer {
  position: absolute;
} */
